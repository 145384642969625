<template>
    <BT-Dialog
        :block="block"
        :buttonClass="buttonClass"
        :text="text"
        :icon="icon"
        :getOnOpen="getOrderItems"
        fullscreen
        cardTextClass="ma-0 pa-0"
        cardClass="ma-0 pa-0"
        @ok="save"
        :small="small">
        <template>
            <v-container fluid>
                <v-expand-transition>
                    <v-row v-if="isLengthyArray(allOrderItems)" dense>
                        <Customer-Order-Item
                            v-for="(lItem, index) in allOrderItems"
                            :key="index"
                            :item="lItem"
                            canEditPrice
                            canEdit
                            isGridItem
                            width="225"
                            @change="updateOrder" />
                    </v-row>
                </v-expand-transition>

                <v-expand-transition>
                    <v-row dense v-if="isLengthyArray(autoOrderItems)">
                        <v-expand-transition v-for="(item, index) in autoOrderItems" :key="index">
                            <Customer-Order-Item 
                                :item="item"
                                :canEditPrice="false"
                                :canEdit="true"
                                width="100%" />
                        </v-expand-transition>
                    </v-row>
                </v-expand-transition>

                <v-row v-if="copiedOrder != null" dense>
                    <v-card outlined dense elevation="1" class="text-right" width="100%">
                        <v-card-text>
                            <h4 class="text-right ma-2"><span class="mr-3">SUBTOTAL:</span> <span>{{ copiedOrder.subTotal | toCurrency }}</span></h4>
                            <h4 class="text-right ma-2"><span class="mr-3">GST:</span> {{ copiedOrder.taxTotal | toCurrency }}</h4>
                            <h3 class="text-right ma-2"><span class="mr-3">TOTAL:</span> {{ copiedOrder.amountTotal | toCurrency }}</h3>
                        </v-card-text>
                    </v-card>
                </v-row>
            </v-container>
            <v-spacer />
            
        </template>
    </BT-Dialog>
</template>

<script>
import { calculateAutoItems, loadOrderItems, updateOrder } from '~tools/helpers.js';

export default {
    name: 'BT-Order-Items-Dialog',
    components: {
        BTDialog: () => import('~components/BT-Dialog.vue'),
        CustomerOrderItem: () => import('~home/customer-orders/Customer-Order-Item.vue'),
    },
    data: function() {
        return {
            allOrderItems: [],
            autoOrderItems: [],
            copiedOrder: null,
            oldAutoOrderItems: [] //for trying to retain ids and rowversions when editing (not creating)
        }
    },
    props: {
        block: {
            type: Boolean,
            default: true
        },
        buttonClass: {
            type: String,
            default: 'primary'
        },
        icon: {
            type: String,
            default: 'mdi-cube-unfolded'
        },
        order: null,
        policy: null,
        refreshToggle: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: 'Edit Order Items'
        }
    },
    watch: {
        refreshToggle() {
            this.updateOrder();
        }
    },
    methods: {
        getOrderItems() {
            if (this.policy == null || this.order == null) {
                return;
            }

            this.copiedOrder = this.copyDeep(this.order);

            this.oldAutoOrderItems = this.copiedOrder.orderItems.filter(x => x.productID == null);

            //remove auto items in copied order
            this.copiedOrder.orderItems = this.copiedOrder.orderItems.filter(x => x.productID != null);

            //sort?
            this.allOrderItems = loadOrderItems(this.copiedOrder, this.policy);

            //this.autoOrderItems = calculateAutoItems(this.policy, this.allOrderItems);

            this.updateOrder();

            return this.allOrderItems;
        },
        save() {
            //attempt to reconcile old auto order item ids and row versions
            this.copiedOrder.orderItems.forEach(x => {
                if (x.productID == null && x.lineItemCode != null) {
                    var oldAutoItem = this.oldAutoOrderItems.find(y => y.lineItemCode == x.lineItemCode);
                    if (oldAutoItem != null) {
                        x.id = oldAutoItem.id;
                        x.rowVersion = oldAutoItem.rowVersion;
                    }
                }
            })

            this.$emit('ok', this.copiedOrder ); //{ order: this.copiedOrder, autoOrderItems: this.autoOrderItems });
        },
        updateOrder() {
            console.log('updating items');
            this.autoOrderItems = calculateAutoItems(this.policy, this.allOrderItems, this.copiedOrder);
            
            updateOrder(
                this.copiedOrder,
                this.policy,
                this.allOrderItems,
                this.autoOrderItems);
        }
    }
}
</script>